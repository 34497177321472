@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
  font-size: 16px;
  font-family: 'Ubuntu', sans-serif;
  background-color: #000000;
  background-image: url('../img/bg.svg');
  color: #ffffff;
}

#root{
    max-width: 1250px;
    margin: auto;
}

.logo {
  width: 240px;
  margin: auto;
}

header, footer{
    margin: auto;
    padding: 1rem 0;
    text-align: center;
}

main{
    min-height: 60vh;
    padding: 1rem;
    overflow: hidden;
}

.linear, .linear-tab{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}

img{
    max-width: 100%;
}

nav {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto 100px;
    padding: 1rem 0;
}

.nav-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 200px;
    right: 1.8rem;
    left: 1.8rem;
    padding: 0;
    margin: 1rem 0 0;
    background-color: #000000;
    text-align: left;
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem 0.1rem #EDC33F;
}

.nav-item:first-child{
    padding: 1.2rem;
    cursor: pointer;
}

.nav-item > a{
    display: block;
    padding: 1.2rem;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
}

.nav-item > a:hover {
    cursor: pointer;
    background: #EDC33F;
    color: #000000;
    font-weight: bold;
}

.nav-item.hamburger-menu:hover {
    background: transparent;
}

.nav-item .active {
    background-color: #EDC33F;
    color: #000000;
    font-weight: bold;
}

.menu-icon{
    width: 2rem;
    float: right;
    color: #858E8E;
}

.hamburger-menu ~ .nav-item{
    display: none;
}

.sleek-heading{
    margin: 2rem auto 1rem;
    color: #EDC33F;
    text-align: center;
    font-size: 2rem;
}

.property, .property-detail{
    width: 100%;
    max-width: 900px;
    padding: 0.5rem 1rem;
}

.property:hover{
    transform: scale(1.1);
    cursor: pointer;
}

.property-container{
    margin: 1rem auto;
    padding: 1rem;
    background-color: #222222;
    border-radius: 0.5rem;
    overflow: hidden;
}

.property-id{
    display: none;
}

.property-image {
    text-align: center;
}

.album {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    cursor: grab;
}
.album-image {
    width: 190px;
    margin: 1.5rem 1.5rem 1.5rem 0;
}
.album-image:hover {
    transform: scale(1.2);
    box-shadow: 0 0 1rem 0.1rem #EDC33F;
}

.album::-webkit-scrollbar {
    height: 0.5rem;
    cursor: pointer;
}
.album::-webkit-scrollbar-thumb {
    background: #EDC33F; 
    border-radius: 0.5rem;
    cursor: pointer;
}
.album::-webkit-scrollbar-thumb:hover {
    background: #EDC33F; 
    box-shadow: 0 0 1rem 0.1rem #EDC33F;
    cursor: pointer;
}

.property-type{
    display: block;
    margin: 1rem auto;
    font-size: 1rem;
    text-align: center;
}

.property-title{
    margin: 1.5rem 0 1rem;
}

.property-price{
    line-height: 2rem;
    text-align: right;
}

.property-address{
    color: #EDC33F;
    line-height: 1.8rem;
    /* background: rgb(184,140,30);
    background: linear-gradient(0deg, rgba(184,140,30,1) 0%, rgba(192,146,35,1) 50%, rgba(243,200,65,1) 100%); */
}

.property-description{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #c4c4c4;
    line-height: 1.8rem;
    overflow: hidden;
}

.page > .property-container{
    margin: 2.3rem auto;
    padding: 1rem;
}

.page h3 {
    margin: 2rem auto;
    line-height: 2rem;
}

.page p {
    margin: 2rem auto;
    font-size: 1.1rem;
    line-height: 1.8rem;
}

.page li {
    margin: 1.5rem auto;
    line-height: 1.5rem;
}

.page a {
   color: #EDC33F;
}

.page form {
    text-align: center;
}

.page input, .page textarea {
    width: 100%;
    max-width: 98.5%;
    height: 50px;
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.2rem;
    background-color: #353535;
    color: #EDC33F;
    border: none;
    outline: none;
}

.page input:focus, .page textarea:focus {
    box-shadow: 0 0 0.5rem 0.1rem #EDC33F;
}

.page input::placeholder{
    color:#EDC33F;
}

.page textarea::placeholder{
    color:#EDC33F;
}

.page .input-group{
    display: flex;
    flex-direction: column;
}

.page textarea{
    min-height: 160px;
}

.sleek-btn{
    width: 200px;
    height: 50px;
    margin: 20px auto;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    background-color: #EDC33F;
    color: #000000;
    cursor: pointer;
}

.sleek-btn:hover{
    font-weight: bold;
}

.flex-container {
    display: flex;
    flex-direction: column;
}

.flex-container > article {
    order: 2;
}
.flex-container > div {
    padding: 1rem;
    text-align: center;
}
.flex-container ul, .flex-container ol {
    padding-left: 1rem;
}
.flex-container img {
    width: 100%;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem 0.1rem #EDC33F;
}

footer .company-logos{
    display: flex;
    max-width: 500px;
    margin: 50px auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

footer .company-logos > img{
    max-width: 100px;
}

.copyright{
    margin-top: 2rem;
    color: #EDC33F;
    
}

.loading {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: fixed;
    top: 50%;
    left: 50%;
    font-size: 1rem;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0);
}
@keyframes mulShdSpin {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.5), -1.8em -1.8em 0 0em rgba(255,255,255, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.5), 1.8em -1.8em 0 0em rgba(255,255,255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.5), 2.5em 0em 0 0em rgba(255,255,255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.5), 1.75em 1.75em 0 0em rgba(255,255,255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.5), 0em 2.5em 0 0em rgba(255,255,255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.5), -1.8em 1.8em 0 0em rgba(255,255,255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.5), -2.6em 0em 0 0em rgba(255,255,255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
}

@media screen and (min-width: 630px) {

    .linear{
        flex-direction: row;
    }
    nav {
        margin:0;
    }
    .nav-items {
        position: static !important;
        flex-direction: row;
        text-align: right;
    }
    .hamburger-menu{
        display: none !important;
    }
    .hamburger-menu ~ .nav-item{
        display: block !important;
    }
    .property{
        width: 300px;
    }
    .page > .property-container{
        padding: 1rem 2rem;
    }
    footer .company-logos > img{
        max-width: 120px;
    }
}

@media screen and (min-width: 900px) {

    .linear-tab{
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .sidebar{
        width: 300px;
    }
    .page .input-group{
        flex-direction: row;
    }

}

@media screen and (min-width: 1100px) {

    .flex-container {
        display: flex;
        flex-direction: row;
    }
    .flex-container > article {
        order: 1;
    }
    .flex-container > div {
        order: 2;
    }

}
